import { HTMLAttributes } from 'react';
import { Rec, RecProduct } from 'server/services/recommendation';
import { RecProvider } from 'shared/consts/recommendations';

export type RecProviderProps<T extends HTMLElement = HTMLElement> = Partial<
  Omit<React.HTMLAttributes<T>, 'dangerouslySetInnerHTML'>
> & {
  [Key in `data-${'cnstrc' | 'criteo'}-${string}`]:
    | boolean
    | string
    | undefined;
};

export const getContainerProviderProps = <T extends HTMLElement>({
  provider,
  rec,
}: {
  provider: RecProvider;
  rec?: Rec | null;
}): RecProviderProps<T> => {
  if (!rec) return {};

  switch (provider) {
    case RecProvider.Constructor:
      return {
        'data-cnstrc-recommendations': true,
        'data-cnstrc-recommendations-pod-id': rec.cnstrc?.podId,
        'data-cnstrc-num-results': rec.productData?.length.toString(),
        'data-cnstrc-result-id': rec.cnstrc?.resultId,
      };
    case RecProvider.Criteo:
      return {
        'data-criteo-view-beacon': rec.onViewBeacon,
      };
    default:
      return {};
  }
};

export const getProductProviderProps = <T extends HTMLElement>(
  { provider, product }: { provider: RecProvider; product?: RecProduct | null },
  props: HTMLAttributes<T>,
): RecProviderProps<T> => {
  if (!product) return {};

  switch (provider) {
    case RecProvider.Constructor:
      return {
        'data-cnstrc-item': 'Recommendation',
        'data-cnstrc-item-id': product.itemNumber,
        'data-cnstrc-item-name': product.title,
        'data-cnstrc-strategy-id': product.cnstrc?.strategyId,
        'data-cnstrc-item-variation-id': product.cnstrc?.variationId,
      };
    case RecProvider.Criteo:
      return {
        'data-criteo-view-beacon': product.onViewBeacon,
        onClick: async (...args) => {
          if (product.onClickBeacon && window.navigator.sendBeacon)
            window.navigator.sendBeacon(product.onClickBeacon);
          return props.onClick?.(...args);
        },
      };
    default:
      return {};
  }
};
