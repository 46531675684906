import { decodeHTML } from 'entities';

export const hasTags = (str: string) => str.includes('<');

export const removeHtmlTags = (title: string) =>
  title.replace(/<\/?[a-z]{1,6}\s?\/?>/g, '');

export const clearText = (str: string) => {
  const text = decodeHTML(str);
  return hasTags(text) ? removeHtmlTags(text) : text;
};
