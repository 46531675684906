/* eslint-disable no-underscore-dangle */
class Mutex {
  private _locked = false;

  constructor() {
    this._locked = false;
  }

  async lock(): Promise<boolean> {
    if (this._locked) {
      return false;
    }
    this._locked = true;
    return true;
  }

  unlock(): void {
    this._locked = false;
  }
}

export default Mutex;
