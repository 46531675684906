/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */

import React from 'react';
import { CriteoRecProduct } from 'server/services/recommendation';
import { RecProvider } from 'shared/consts/recommendations';
import { InGridProductCard } from './components/InGrid';
import { ConfigContextType, ConfigProvider } from './context/config';

type InGridProductCardRendererProps = {
  product: CriteoRecProduct;
  provider: RecProvider;
  onLoadBeacon?: string;
  config: ConfigContextType;
};

const InGridProductCardRenderer: React.FC<InGridProductCardRendererProps> = ({
  product,
  provider,
  onLoadBeacon,
  config,
}) => {
  return (
    <ConfigProvider value={config}>
      <InGridProductCard
        provider={provider}
        product={product}
        onLoadBeacon={onLoadBeacon}
      />
    </ConfigProvider>
  );
};

export default InGridProductCardRenderer;
