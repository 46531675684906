export enum RecProvider {
  Constructor = 'recs2',
  Criteo = 'recs3',
}

export enum RecPlacement {
  Criteo1 = 'demo_1',
  Criteo2 = 'demo_2',
  Criteo3 = 'in_grid_banner_1',
  Criteo4 = 'banner_1',
  RecentlyViewed1 = 'recently_viewed_1',
  Homepage1 = 'homepage_1',
  PDP1 = 'pdp_1',
  PDP2 = 'pdp_2',
  PDP3 = 'pdp_3',
  PLP1 = 'plp_1',
  PLP2 = 'plp_2',
  ATB1 = 'atb_1',
  Basket1 = 'basket_1',
  Basket2 = 'basket_2',
  Basket3 = 'basket_3',
  MyAccount1 = 'my_account_1',
  ZeroResults1 = 'zero_results_1',
  InGrid = 'viewCategoryApiDesktop',
}

export enum RenderType {
  InGrid = 'in-grid',
  Carousel = 'carousel',
  Banner = 'banner',
  BannerInGrid = 'banner-in-grid',
  AddToBasket = 'add-to-basket',
}

export enum UserAction {
  CATEGORY = 'category',
  FACET = 'facet',
  PAGINATION = 'pagination',
  SORT_ORDER = 'sort_order',
  RESULT_COUNT = 'result_count',
  BROWSER_ACTION = 'browser_action', // back or forward
}

export const GRID_UPDATE_EVENT = 'plp/grid-update';

export default {};
