import { createContext, useContext } from 'react';
import type { APIReqConfig, APIReqOptions, Rec } from 'server/services/recommendation';
import { RecProvider, RenderType } from 'shared/consts/recommendations';

export interface ConfigContextType extends APIReqConfig {
  options?: APIReqOptions;
  provider: RecProvider;
  renderType?: RenderType;
  rec?: Rec | null;
  noResponse?: boolean;
  customerId?: string;
  keywords?: string;
  category?: string;
}

const ConfigContext = createContext<ConfigContextType>({} as ConfigContextType);

export const { Provider: ConfigProvider } = ConfigContext;

export default function useConfigContext() {
  return useContext(ConfigContext);
}
