/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */
/* eslint-disable no-underscore-dangle */
import eventBus from '@sd-utility/eventbus';
import React from 'react';
import ReactDOM from 'react-dom';
import type {
  CIOProductsRec,
  CIOProductsResBodyEntry,
  CIORecProduct,
  CriteoBannerResBodyEntry,
  CriteoProductsResBodyEntry,
  RecProduct,
} from 'server/services/recommendation';
import {
  GRID_UPDATE_EVENT,
  RenderType,
  UserAction,
} from 'shared/consts/recommendations';
import InGridProductCardRenderer from './InGridProductCardRenderer';
import { dispatchRexInitiated, dispatchRexLoaded } from './rex-events';
import {
  EventBusProps,
  hideGridItems,
  shouldUpdateRecs,
} from './utils/gridUpdateEvent';
import Mutex from './utils/mutex';

export enum Manufacturer {
  Apple = 'Apple',
}

if (!window.__ALL_RECS_QUERIES__) window.__ALL_RECS_QUERIES__ = {};
let isRunning = false;
let lastRun = 0;

const mutex = new Mutex();

// eslint-disable-next-line import/prefer-default-export
export const renderAddToBasket = async (
  rec: CIOProductsRec | null,
  title = '',
) => {
  const containerId = 'recs-atb-container';
  const el = document.getElementById(containerId) as HTMLDivElement | null;
  if (!el || !(rec && rec.productData && rec.productData.length > 0)) return;

  const isCarousel =
    window.universal_variable?.product?.manufacturer === Manufacturer.Apple &&
    rec.productData.length > 1;

  const recProduct = isCarousel
    ? rec.productData.slice(0, 5)
    : rec.productData[0];

  const { AddToBasket } = await import('./components/AddToBasket');
  const { Carousel } = await import('./components/Carousel');

  const callback = () => el.classList.remove('recs-atb--hidden');

  ReactDOM.render(
    isCarousel ? (
      <Carousel
        lCardsPerPage={2}
        mCardsPerPage={2}
        xxsCardsPerPage={2}
        touchPeek
        products={recProduct as RecProduct[]}
        title={title}
        data-testid="recommendations-carousel-add-to-basket"
      />
    ) : (
      <AddToBasket product={recProduct as CIORecProduct} title={title} />
    ),
    el,
    callback,
  );
};

export const getUUID = async () => {
  try {
    const module = (await import('client/utils/sessionStorage')) as {
      setSession: Function;
      getSession: Function;
    };
    const { setSession, getSession } = module;
    const existingId = getSession('criteo_visitorID');
    if (existingId) return existingId;

    const { v4: uuidv4 } = await import('uuid');
    const randomID = uuidv4();

    setSession('criteo_visitorID', randomID);

    return randomID;
  } catch (ex) {
    console.log('getUUIDFailed: ', ex);
    return '';
  }
};

export const getCustomerID = () => window.cnstrcUserId || undefined;

export const getTaxonomyValue = () => {
  const scriptTag = document.getElementById('taxonomyData');
  if (scriptTag && scriptTag.textContent) {
    try {
      const data = JSON.parse(scriptTag.textContent);
      return data.taxonomy;
    } catch (error) {
      return '';
    }
  }
  return '';
};

const dispatchInitiated = () => {
  window.__RECS_INITIATED__ = true;
  dispatchRexInitiated();
};

export const getAllRoots = () =>
  document.querySelectorAll<HTMLDivElement & { _hydrated?: boolean }>(
    'div#recommendations-root',
  );

export const removeAllRoots = () => getAllRoots().forEach((el) => el.remove());

export const isSpecificBrandPage = (href: string): boolean => {
  const brandRegex =
    /apple|macbook|iphone|ipad|airpods|imac|airtag|homepod|samsung|nike|adidas/i;
  return brandRegex.test(href);
};

export const shouldHideRecommendations = (): boolean =>
  isSpecificBrandPage(window.location.href);

// Temporary solution to hide sexual wellness products from Recs
export const filterProducts = (productData: CIORecProduct[]) => {
  if (!productData) return [];

  const wellnessProducts = [
    'W12E5',
    'W12E6',
    'W12E7',
    'W12E8',
    'W12E9',
    'W12EA',
    'W12EB',
    'W12EC',
    'W12ED',
    'W12EE',
    'W12EF',
    'W12EG',
    'W12EH',
    'W12EI',
    'W12EJ',
    'W12EK',
    'W12E0',
    'W12E1',
    'W12E2',
    'W12E3',
    'W12E4',
  ];
  return productData.filter(
    (product) =>
      product.cnstrc && !wellnessProducts.includes(product.cnstrc.variationId),
  );
};

export const renderInGrid = (
  { rec, provider }: CriteoProductsResBodyEntry,
  isSelectedFacet: boolean,
  state: any,
) => {
  const uvListing = window.universal_variable?.listing;
  if (uvListing?.pageType === 'promo') return;
  if (!rec || !rec.productData.length) return;
  try {
    const containers = document.querySelectorAll<HTMLDivElement>(
      'div[data-testid="gallery-recs-container"].grid-item--recs-container',
    );
    /* Don't display inGrid Recs if less than 2/3 products */
    const minGridProducts = window.innerWidth < 768 ? 2 : 3;
    if (rec.productData.length < minGridProducts) {
      return;
    }

    containers.forEach((container, idx) => {
      const product = rec.productData[idx];
      if (!product) return;
      ReactDOM.unmountComponentAtNode(container);
      const shouldTriggerLoadBeacon = idx === 0;
      ReactDOM.render(
        <InGridProductCardRenderer
          product={product}
          provider={provider}
          onLoadBeacon={shouldTriggerLoadBeacon ? rec.onLoadBeacon : ''}
          config={{ ...state.config }}
        />,
        container,
      );
      if (container.innerHTML === '' || isSelectedFacet) {
        container.classList.add('grid-item--hidden');
      } else {
        container.classList.remove('grid-item--hidden');
      }
    });
  } catch (ex) {
    console.error('Failed to Render inGrid Recs', ex);
  }
};

export const renderBannerInGrid = async (
  entry: CriteoBannerResBodyEntry,
  isSelectedFacet: boolean,
  state: any,
) => {
  const uvListing = window.universal_variable?.listing;
  if (uvListing?.pageType === 'promo') return;
  const bannerEl = document.querySelector<HTMLDivElement>(
    'div[data-testid="gallery-banner-container"].grid-item--recs-banner',
  );
  if (!bannerEl || !entry || !entry.rec) return;

  const { default: BannerContainer } = await import('./BannerContainer');

  ReactDOM.render(
    <BannerContainer
      brand={window.universal_variable?.brand}
      config={{
        ...state.config,
        renderType: entry?.renderType,
        rec: entry?.rec,
        noResponse: !entry?.rec,
      }}
    />,
    bannerEl,
    () => {
      if (!isSelectedFacet && bannerEl) {
        bannerEl.classList.remove('grid-item--hidden');
      }
    },
  );
};

export const runRecommendations = async (
  fromEventBus = false,
  isSelectedFacet = false,
): Promise<void> => {
  await mutex.lock();
  try {
    if (isRunning || Date.now() - lastRun < 3000) return;

    isRunning = true;
    lastRun = Date.now();

    try {
      const uv = window.universal_variable;
      if (!uv) {
        console.error(new Error('universal_variable_unavailable'));
        dispatchInitiated();
        return removeAllRoots();
      }

      const { default: Worker } = await import('./rex.worker');
      const worker = new Worker();

      const uvCopy = { ...uv };
      delete uvCopy.events;

      const postMessage = async () =>
        worker.postMessage({
          uv: uvCopy,
          qs: window.__ALL_RECS_QUERIES__,
          options: {
            ...window.__GLOBAL_RECS_STATE__,
            screenWidth: window.innerWidth,
            visitorId: await getUUID(),
          },
          customerID: getCustomerID(),
          taxonomy: getTaxonomyValue(),
        });

      worker.onmessage = async ({ data: { options, recs } }) => {
        if (!recs) {
          dispatchInitiated();
          worker.terminate();
          return removeAllRoots();
        }

        document
          .querySelectorAll<HTMLDivElement & { _hydrated?: boolean }>(
            'div#recommendations-root',
          )
          .forEach((el) => {
            const stateId = el.parentElement?.getAttribute('data-stateid');
            const debugText = 'previously hydrated, skipping hydration for';
            if (!fromEventBus) {
              // * prevents hydrating element multiple times
              if (el._hydrated) return console.debug(debugText, el);
            } else if (!stateId?.includes('recs3')) {
              if (el._hydrated) return console.debug(debugText, el);
            }
            if (!stateId) {
              console.error(new Error('state_id_not_found'));
              return el.remove();
            }
            // Remove Criteo Recs for specific brands
            if (stateId.includes('recs3') && shouldHideRecommendations()) {
              return el.remove();
            }
            const state = window.__ALL_RECS_QUERIES__[stateId];
            if (!state) {
              console.error(new Error('state_not_found'));
              return el.remove();
            }
            const render = async (
              entry: CIOProductsResBodyEntry | CriteoProductsResBodyEntry,
            ) => {
              // to avoid unnecessarily importing the app if the carousel is just going to disappear
              if (!entry.rec?.productData.length) return el.remove();

              const { default: App } = await import('./App');
              ReactDOM.render(
                <App
                  brand={window.universal_variable?.brand}
                  config={{
                    ...state.config,
                    options,
                    renderType: entry?.renderType,
                    rec: entry?.rec,
                    noResponse: !entry?.rec,
                  }}
                />,
                el,
                () => {
                  el._hydrated = true;
                  dispatchRexLoaded({
                    provider: state.config.provider,
                    placement: state.config.placement,
                  });
                },
              );
            };

            const res = recs[stateId];
            let inGridRecReturned = false;
            let carouselRecReturned = false;
            let inGridBannerReturned = false;

            if (!res) return el.remove();
            res.forEach((entry) => {
              if (
                entry.provider === 'recs2' &&
                entry.rec &&
                entry.rec.productData
              ) {
                entry.rec.productData = filterProducts(entry.rec.productData);
              }

              switch (entry.renderType) {
                case RenderType.BannerInGrid:
                  inGridBannerReturned = true;
                  return renderBannerInGrid(entry, isSelectedFacet, state);
                case RenderType.InGrid:
                  inGridRecReturned = true;
                  return renderInGrid(entry, isSelectedFacet, state);
                case RenderType.AddToBasket:
                  return renderAddToBasket(entry.rec, entry.rec?.title);
                default:
                  if (entry.provider === 'recs3') {
                    carouselRecReturned = true;
                  }
                  return render(entry as any);
              }
            });
            // PLP hide below grid skeleton if no recs are returned in carousel
            if (
              (inGridRecReturned || inGridBannerReturned) &&
              !carouselRecReturned
            ) {
              el.remove();
            }
          });

        dispatchInitiated();
        worker.terminate();
      };

      await postMessage();
    } finally {
      isRunning = false;
    }
  } finally {
    mutex.unlock();
  }
};

if (!window.__RECS_INITIATING__) {
  window.__RECS_INITIATING__ = true;
  const isSelectedFacet =
    !!window.universal_variable?.listing?.filters?.numSelections;

  runRecommendations(false, isSelectedFacet);
}

// istanbul ignore next
if (module.hot) {
  module.hot.accept((error) => error && console.error(error));
}

const subscription = eventBus.subscribe(
  GRID_UPDATE_EVENT,
  (e: EventBusProps) => {
    if (e?.pageType === 'search') return;

    if (shouldUpdateRecs(e)) {
      runRecommendations(true, e?.isSelectedFacet);
    } else if (e?.eventTrigger === UserAction.FACET && e?.isSelectedFacet) {
      hideGridItems();
    }
  },
);

window.addEventListener('beforeunload', () => subscription.unsubscribe(), {
  once: true,
});
