import { UserAction } from 'shared/consts/recommendations';

export interface EventBusProps {
  pageType: string;
  eventTrigger: UserAction;
  isSelectedFacet: boolean;
}

export const shouldUpdateRecs = (e: EventBusProps) =>
  !!e?.eventTrigger &&
  e.eventTrigger !== UserAction.SORT_ORDER &&
  e.eventTrigger !== UserAction.RESULT_COUNT &&
  (e.eventTrigger !== UserAction.FACET || !e.isSelectedFacet);

const hideElements = (selector: string, className: string) => {
  document.querySelectorAll<HTMLDivElement>(selector).forEach(async (el) => {
    if (!el) {
      return;
    }

    el.classList.add(className);
  });
};

const hideFeaturedProducts = () => {
  hideElements(
    'div[data-testid="gallery-recs-container"].grid-item--recs-container',
    'grid-item--hidden',
  );
};

const hideInGridBanner = () => {
  hideElements(
    'div[data-testid="gallery-banner-container"].grid-item--recs-banner',
    'grid-item--hidden',
  );
};

export const hideGridItems = () => {
  hideFeaturedProducts();
  hideInGridBanner();
};
