interface Config {
  provider: (typeof ProviderMap)[keyof typeof ProviderMap];
  placement: string;
}

enum RexEvent {
  Initiated = 'rex-events:initiated',
  Loaded = 'rex-events:loaded',
}

export const ProviderMap = {
  constructor: 'recs2',
  criteo: 'recs3',
} as const;

type EventName = RexEvent | `${RexEvent}:${Config['provider']}:${string}`;

const createEvent = (event: EventName, eventInitDict?: EventInit) =>
  new Event(event, eventInitDict);

/**
 * @private
 */
export const dispatchRexInitiated = (eventInitDict?: EventInit) => {
  document.dispatchEvent(createEvent(RexEvent.Initiated, eventInitDict));
};

/**
 *
 * @returns function to remove the event listener
 */
export const addRexInitiatedListener = (
  listener: (e: Event) => unknown,
  options?: AddEventListenerOptions,
) => {
  document.addEventListener(RexEvent.Initiated, listener, {
    once: true,
    ...options,
  });
  return () => document.removeEventListener(RexEvent.Initiated, listener);
};

/**
 * @private
 */
export const dispatchRexLoaded = (
  { provider, placement }: Config,
  eventInitDict?: EventInit,
) => {
  document.dispatchEvent(
    createEvent(`${RexEvent.Loaded}:${provider}:${placement}`, eventInitDict),
  );
};

export const addRexLoadedListener = (
  {
    provider,
    placement,
  }: Omit<Config, 'provider'> & { provider: keyof typeof ProviderMap },
  listener: (e: Event) => unknown,
  options?: AddEventListenerOptions,
) => {
  // eslint-disable-next-line security/detect-object-injection
  const eventName: EventName = `${RexEvent.Loaded}:${ProviderMap[provider]}:${placement}`;
  document.addEventListener(eventName, listener, { once: true, ...options });
  return () => document.removeEventListener(eventName, listener);
};
