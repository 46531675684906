import useConfigContext from 'client/context/config';
import { useEffect } from 'react';
import { RecProvider } from 'shared/consts/recommendations';

export const useCriteoLoadEvent = (beacon?: string) => {
  const { provider } = useConfigContext();

  useEffect(() => {
    if (provider !== RecProvider.Criteo || !beacon) return;
    window.navigator.sendBeacon(beacon);
  }, [provider, beacon]);
};

export const useCriteoViewEvent = (
  ref: React.MutableRefObject<HTMLDivElement | null>,
) => {
  const { provider } = useConfigContext();

  useEffect(() => {
    if (
      provider !== RecProvider.Criteo ||
      typeof IntersectionObserver === 'undefined' ||
      !ref?.current
    )
      return;

    const timeouts = new Map<Element, NodeJS.Timeout>();
    /* istanbul ignore next */
    const observer = new IntersectionObserver(
      (entries) =>
        entries.forEach((e) => {
          const timeout = timeouts.get(e.target);

          const beacon = e.target.getAttribute('data-criteo-view-beacon');
          if (!beacon) return;

          if (!e.isIntersecting && timeout) {
            clearTimeout(timeout);
            timeouts.delete(e.target);
            return;
          }

          if (e.isIntersecting && !timeout) {
            timeouts.set(
              e.target,
              setTimeout(() => {
                window.navigator.sendBeacon(beacon);
                observer.unobserve(e.target);
              }, 1000),
            );
          }
        }),
      { threshold: 0.5 },
    );

    const currentElement = ref.current;
    if (
      currentElement &&
      currentElement.getAttribute('data-criteo-view-beacon')
    ) {
      observer.observe(currentElement);
    }

    const beaconElements = currentElement?.querySelectorAll(
      '[data-criteo-view-beacon]',
    );
    beaconElements?.forEach((el) => observer.observe(el));

    return () => {
      observer.disconnect();
      timeouts.forEach(clearTimeout);
    };
  }, [ref, provider]);
};
